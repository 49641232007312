import "react-toastify/dist/ReactToastify.min.css";

import { Button, Form } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  useGetMerchantConfigQuery,
  useUpdateMerchantConfigMutation,
} from "../../../../../graphql/__generated__/graphql_types";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const defaultConfigLevels = [
  {
    name: "Basic",
    from: 50,
    to: 250,
    discountInPercent: 0.03,
    mainColor: "sandybrown",
  },
  {
    name: "Medium",
    from: 251,
    to: 500,
    discountInPercent: 0.05,
    mainColor: "darkgray",
  },
  {
    name: "Top",
    from: 501,
    to: 1000,
    discountInPercent: 0.08,
    mainColor: "rgb(216, 117, 217)",
  },
];

const MerchantConfig = () => {
  const [formState, setFormState] = useState<any>({});
  const [configState, setConfigState] = useState<any>([]);
  const { data, loading } = useGetMerchantConfigQuery();
  const [updateMerchant] = useUpdateMerchantConfigMutation();

  useEffect(() => {
    if (data && data.merchant) {
      if (data.merchant.levelsConfig) {
        setConfigState(data.merchant.levelsConfig);
      } else setConfigState(defaultConfigLevels);

      setFormState(data.merchant);
    } else setConfigState(defaultConfigLevels);
  }, [data]);

  const handleChange = (event: any) => {
    event.persist();
    const name = event.target.name;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const handleConfigChange = (event: any, index: any) => {
    event.persist();
    const name = event.target.name;

    let configArray = [...configState];
    let config = { ...configArray[index], [name]: event.target.value };
    configArray[index] = config;

    setConfigState(configArray);
  };

  const handleSave = async () => {
    const pointsPerCent = parseFloat(formState.pointsPerCent);
    const welcomePoints = parseInt(formState.welcomePoints);
    const levelsConfig = JSON.stringify(configState);

    await updateMerchant({
      variables: {
        pointsPerCent,
        welcomePoints,
        levelsConfig,
      },
    }).then(() =>
      toast.success("Changes are saved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      })
    );
  };

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <h1>Merchants Config</h1>

      <ToastContainer />

      <Form>
        <Form.Input
          width={8}
          disabled
          name="name"
          label="Name"
          value={formState.name || ""}
        />
        <Form.Input
          width={8}
          disabled
          name="merchantName"
          label="merchantName"
          value={formState.merchantName || ""}
        />
        <Form.Group>
          <Form.Input
            label="Points Per Cent.
            (Customer gets 50 points per 1 Euro spend)"
            name="pointsPerCent"
            value={formState.pointsPerCent || ""}
            onChange={handleChange}
          />
          <Form.Input
            width={5}
            label="Welcome Points"
            name="welcomePoints"
            value={formState.welcomePoints || ""}
            onChange={handleChange}
          />
        </Form.Group>

        {Object.values(configState).map((item: any, index) => {
          return (
            <Form.Group widths={6} key={index}>
              <Form.Input
                label={`Level ${index + 1} name`}
                name="name"
                value={item.name}
                onChange={(e) => handleConfigChange(e, index)}
              />
              <Form.Input
                label={`Level ${index + 1} from`}
                name="from"
                value={item.from}
                onChange={(e) => handleConfigChange(e, index)}
              />
              <Form.Input
                label={`Level ${index + 1} to`}
                name="to"
                value={item.to}
                onChange={(e) => handleConfigChange(e, index)}
              />
              <Form.Input
                label={`Level ${index + 1} Discount - ${
                  item.discountInPercent * 100
                }%`}
                name="discountInPercent"
                value={item.discountInPercent}
                onChange={(e) => handleConfigChange(e, index)}
              />
              <Form.Field
                style={{
                  background: `${item.mainColor}`,
                  paddingBottom: 6,
                  borderRadius: 5,
                }}
              >
                <label>{`Level ${index + 1} Color`} </label>
                <input
                  name="mainColor"
                  value={item.mainColor}
                  onChange={(e) => handleConfigChange(e, index)}
                />
              </Form.Field>
            </Form.Group>
          );
        })}
        <Button primary onClick={handleSave} content="Save"></Button>
      </Form>
    </>
  );
};

export default MerchantConfig;
