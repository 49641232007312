import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Admin_Date: any;
  Date: any;
};

export type Query = {
  __typename?: 'Query';
  admin_events?: Maybe<Array<Maybe<Admin_Events>>>;
  admin_merchant?: Maybe<Admin_Merchant>;
  admin_cards?: Maybe<Array<Maybe<Admin_LinkedCard>>>;
  admin_users?: Maybe<Array<Maybe<Admin_User>>>;
  admin_transactions?: Maybe<Array<Maybe<Admin_Transaction>>>;
  ecomEmail?: Maybe<EcomEmail>;
  card?: Maybe<LinkedCard>;
  me?: Maybe<User>;
  merchant?: Maybe<Merchant>;
  paymentMethods: PaymentMethodResponse;
  pos_getPaymentInfo?: Maybe<Pos_PaymentInfo>;
  pos_getLoyaltyInfo?: Maybe<Pos_LoyaltyCard>;
};


export type QueryEcomEmailArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCardArgs = {
  shopperReference: Scalars['String'];
};


export type QueryPaymentMethodsArgs = {
  amountInCent: Scalars['Int'];
  shopperReference?: Maybe<Scalars['String']>;
};


export type QueryPos_GetPaymentInfoArgs = {
  orderId: Scalars['String'];
};


export type QueryPos_GetLoyaltyInfoArgs = {
  customerKey: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  admin_deleteUser: Scalars['String'];
  admin_deleteCard: Scalars['String'];
  admin_updateMerchant?: Maybe<Admin_Merchant>;
  updateNotificationStrategy: Scalars['String'];
  register?: Maybe<User>;
  linkCardToAccount?: Maybe<Scalars['String']>;
  login?: Maybe<User>;
  redeemPoints: Scalars['String'];
  registerWithPayment: CreatePaymentResponse;
  validateAppleMerchant: ValidationResponse;
  pos_startPayment?: Maybe<Pos_TerminalResponse>;
  pos_startPaymentAdyen?: Maybe<Pos_TerminalResponseAdyen>;
  pos_createCard?: Maybe<Pos_LoyaltyCard>;
};


export type MutationAdmin_DeleteUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationAdmin_DeleteCardArgs = {
  id: Scalars['String'];
};


export type MutationAdmin_UpdateMerchantArgs = {
  levelsConfig: Scalars['String'];
  welcomePoints: Scalars['Int'];
  pointsPerCent: Scalars['Float'];
};


export type MutationUpdateNotificationStrategyArgs = {
  strategy?: Maybe<NotificationStrategy>;
  input?: Maybe<UpdateNotificationStrategyInput>;
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};


export type MutationLinkCardToAccountArgs = {
  shopperReference: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRedeemPointsArgs = {
  points?: Maybe<Scalars['Int']>;
};


export type MutationRegisterWithPaymentArgs = {
  input: CreateRegisterPaymentInput;
};


export type MutationValidateAppleMerchantArgs = {
  validationURL?: Maybe<Scalars['String']>;
};


export type MutationPos_StartPaymentArgs = {
  amount: Scalars['Int'];
};


export type MutationPos_StartPaymentAdyenArgs = {
  amount: Scalars['Int'];
};


export type MutationPos_CreateCardArgs = {
  customerKey: Scalars['String'];
  hash: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type Admin_CardUser = {
  __typename?: 'Admin_CardUser';
  id: Scalars['String'];
  email: Scalars['String'];
};

export type Admin_LinkedCard = {
  __typename?: 'Admin_LinkedCard';
  id: Scalars['String'];
  shopperReference: Scalars['String'];
  paymentMethod: Scalars['String'];
  User?: Maybe<Admin_CardUser>;
  phone?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
  createdAt: Scalars['Admin_Date'];
  cardInfos?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  provider: Scalars['String'];
  registrationStrategy?: Maybe<Scalars['String']>;
  Transaction?: Maybe<Array<Maybe<Admin_Transaction>>>;
};

export type Admin_Card = {
  __typename?: 'Admin_Card';
  id: Scalars['String'];
  paymentMethod?: Maybe<Scalars['String']>;
  shopperReference?: Maybe<Scalars['String']>;
};

export type Admin_EcomEmail = {
  __typename?: 'Admin_EcomEmail';
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type Admin_Coupon = {
  __typename?: 'Admin_Coupon';
  id: Scalars['String'];
};

export type Admin_Transaction = {
  __typename?: 'Admin_Transaction';
  id: Scalars['String'];
  points?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Admin_Date']>;
  note?: Maybe<Scalars['String']>;
  Card?: Maybe<Admin_Card>;
  EcomEmail?: Maybe<Admin_EcomEmail>;
};

export type Admin_User = {
  __typename?: 'Admin_User';
  id: Scalars['ID'];
  email: Scalars['String'];
  createdAt?: Maybe<Scalars['Admin_Date']>;
  name?: Maybe<Scalars['String']>;
  Card?: Maybe<Array<Maybe<Admin_Card>>>;
  EcomEmail?: Maybe<Array<Maybe<Admin_EcomEmail>>>;
  points?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  Coupon?: Maybe<Array<Maybe<Admin_Coupon>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type Admin_LevelConfig = {
  __typename?: 'Admin_LevelConfig';
  mainColor?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  discountInPercent?: Maybe<Scalars['Float']>;
};

export type Admin_Merchant = {
  __typename?: 'Admin_Merchant';
  id: Scalars['ID'];
  levelsConfig?: Maybe<Scalars['String']>;
  levelsConfigData?: Maybe<Array<Maybe<Admin_LevelConfig>>>;
  pointsPerCent?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  welcomePoints?: Maybe<Scalars['Int']>;
  merchantName?: Maybe<Scalars['String']>;
};

export type Admin_Events = {
  __typename?: 'Admin_Events';
  _id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Admin_Date']>;
  data?: Maybe<Scalars['String']>;
};

export enum NotificationStrategy {
  Phone = 'Phone',
  Email = 'Email',
  Push = 'Push'
}

export enum CardProvider {
  Adyen = 'Adyen',
  PayNl = 'PayNL'
}

export type Transaction = {
  __typename?: 'Transaction';
  points: Scalars['Int'];
};


export type LinkedCard = {
  __typename?: 'LinkedCard';
  id: Scalars['String'];
  shopperReference: Scalars['String'];
  paymentMethod: Scalars['String'];
  user?: Maybe<Scalars['String']>;
  User?: Maybe<User>;
  phone?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  cardInfos?: Maybe<Scalars['String']>;
  provider?: Maybe<CardProvider>;
  registrationStrategy?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type Events = {
  __typename?: 'Events';
  _id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['String']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedAt?: Maybe<Scalars['Date']>;
  revoked?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['String']>;
  amountInCent?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type EventsCollection = {
  __typename?: 'EventsCollection';
  items?: Maybe<Array<Events>>;
  eventSet?: Maybe<Array<Scalars['String']>>;
  count: Scalars['Int'];
};


export type EventsCollectionItemsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<Scalars['String']>>;
};


export type EventsCollectionCountArgs = {
  types?: Maybe<Array<Scalars['String']>>;
};

export type CouponsCollection = {
  __typename?: 'CouponsCollection';
  items?: Maybe<Array<Coupon>>;
  count: Scalars['Int'];
};


export type CouponsCollectionItemsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  redeemed?: Maybe<Scalars['Boolean']>;
};


export type CouponsCollectionCountArgs = {
  redeemed?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  pushKey?: Maybe<Scalars['String']>;
  notificationStrategy?: Maybe<NotificationStrategy>;
  cards?: Maybe<Array<Maybe<LinkedCard>>>;
  ecomEmails?: Maybe<Array<EcomEmail>>;
  points?: Maybe<Scalars['Int']>;
  authData?: Maybe<AuthProviderData>;
  coupons?: Maybe<Array<Coupon>>;
  couponsCollection?: Maybe<CouponsCollection>;
  events?: Maybe<Array<Events>>;
  eventsCollection?: Maybe<EventsCollection>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};


export type UserEventsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<Scalars['String']>>;
};

export type AuthProviderData = {
  __typename?: 'AuthProviderData';
  picture?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type EcomEmail = {
  __typename?: 'EcomEmail';
  email: Scalars['String'];
  verified: Scalars['Boolean'];
  verifiedAt?: Maybe<Scalars['Date']>;
};

export type PaymentGroups = {
  __typename?: 'PaymentGroups';
  name: Scalars['String'];
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentMethodDetailsItems = {
  __typename?: 'PaymentMethodDetailsItems';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PaymentMethodDetails = {
  __typename?: 'PaymentMethodDetails';
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<PaymentMethodDetailsItems>>>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  brands?: Maybe<Array<Maybe<Scalars['String']>>>;
  details?: Maybe<Array<Maybe<PaymentMethodDetails>>>;
  name?: Maybe<Scalars['String']>;
  supportsRecurring?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type PaymentMethodResponse = {
  __typename?: 'PaymentMethodResponse';
  groups?: Maybe<Array<Maybe<PaymentGroups>>>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type LevelConfig = {
  __typename?: 'LevelConfig';
  mainColor?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  discountInPercent?: Maybe<Scalars['Float']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  levelsConfig?: Maybe<Scalars['String']>;
  levelsConfigData?: Maybe<Array<LevelConfig>>;
  pointsPerCent?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  welcomePoints?: Maybe<Scalars['Int']>;
  merchantName?: Maybe<Scalars['String']>;
};

export type PaymentMethodInput = {
  type?: Maybe<Scalars['String']>;
  applepayToken?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  encryptedCardNumber?: Maybe<Scalars['String']>;
  encryptedExpiryMonth?: Maybe<Scalars['String']>;
  encryptedExpiryYear?: Maybe<Scalars['String']>;
  encryptedSecurityCode?: Maybe<Scalars['String']>;
};

export type CreateRegisterPaymentInput = {
  paymentMethod: PaymentMethodInput;
  returnURL: Scalars['String'];
};

export type CreatePaymentInput = {
  shopperReference: Scalars['String'];
  amountInCent: Scalars['Int'];
  paymentMethod: PaymentMethodInput;
  returnUrl: Scalars['String'];
};

export type CreateUserInput = {
  email?: Maybe<Scalars['String']>;
  shopperReference: Scalars['String'];
  userToLink?: Maybe<Scalars['String']>;
};

export type CreatePaymentResponse = {
  __typename?: 'CreatePaymentResponse';
  redirectURL?: Maybe<Scalars['String']>;
  resultCode: Scalars['String'];
  shopperReference?: Maybe<Scalars['String']>;
};

export type ValidationResponse = {
  __typename?: 'ValidationResponse';
  epochTimestamp: Scalars['String'];
  expiresAt: Scalars['String'];
  merchantSessionIdentifier: Scalars['String'];
  nonce: Scalars['String'];
  merchantIdentifier: Scalars['String'];
  domainName: Scalars['String'];
  displayName: Scalars['String'];
  signature: Scalars['String'];
};

export type UpdateNotificationStrategyInput = {
  pushKey?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Pos_PaymentInfo = {
  __typename?: 'POS_PaymentInfo';
  id: Scalars['String'];
  orderId: Scalars['String'];
  customerKey: Scalars['String'];
  data?: Maybe<Scalars['String']>;
};

export type Pos_User = {
  __typename?: 'POS_User';
  name: Scalars['String'];
  email: Scalars['String'];
};

export type Pos_LoyaltyCard = {
  __typename?: 'POS_LoyaltyCard';
  id: Scalars['String'];
  shopperReference: Scalars['String'];
  User?: Maybe<Pos_User>;
};

export type Pos_TerminalResponse = {
  __typename?: 'POS_TerminalResponse';
  transactionId: Scalars['String'];
  hash: Scalars['String'];
};

export type Pos_TerminalResponseAdyen = {
  __typename?: 'POS_TerminalResponseAdyen';
  payment: Scalars['String'];
};

export type GetCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardsQuery = (
  { __typename?: 'Query' }
  & { cards?: Maybe<Array<Maybe<(
    { __typename?: 'Admin_LinkedCard' }
    & Pick<Admin_LinkedCard, 'id' | 'shopperReference' | 'paymentMethod' | 'createdAt' | 'verified' | 'cardInfos' | 'registrationStrategy' | 'provider'>
    & { user?: Maybe<(
      { __typename?: 'Admin_CardUser' }
      & Pick<Admin_CardUser, 'email'>
    )>, transactions?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_Transaction' }
      & Pick<Admin_Transaction, 'points'>
    )>>> }
  )>>> }
);

export type DeleteCardMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'admin_deleteCard'>
);

export type CardsQueryVariables = Exact<{
  shopperReference: Scalars['String'];
}>;


export type CardsQuery = (
  { __typename?: 'Query' }
  & { card?: Maybe<(
    { __typename?: 'LinkedCard' }
    & Pick<LinkedCard, 'phone'>
  )> }
);

export type CreateCardMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
  hash: Scalars['String'];
  customerKey: Scalars['String'];
}>;


export type CreateCardMutation = (
  { __typename?: 'Mutation' }
  & { createCard?: Maybe<(
    { __typename?: 'POS_LoyaltyCard' }
    & Pick<Pos_LoyaltyCard, 'id' | 'shopperReference'>
    & { User?: Maybe<(
      { __typename?: 'POS_User' }
      & Pick<Pos_User, 'name'>
    )> }
  )> }
);

export type CheckCardQueryVariables = Exact<{
  shopperReference: Scalars['String'];
}>;


export type CheckCardQuery = (
  { __typename?: 'Query' }
  & { card?: Maybe<(
    { __typename?: 'LinkedCard' }
    & Pick<LinkedCard, 'shopperReference' | 'paymentMethod' | 'verified'>
  )> }
);

export type CouponsQueryVariables = Exact<{
  redeemed?: Maybe<Scalars['Boolean']>;
}>;


export type CouponsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { couponsCollection?: Maybe<(
      { __typename?: 'CouponsCollection' }
      & { items?: Maybe<Array<(
        { __typename?: 'Coupon' }
        & Pick<Coupon, 'code' | 'redeemed' | 'amountInCent' | 'redeemedAt' | 'createdAt'>
      )>> }
    )>, coupons?: Maybe<Array<(
      { __typename?: 'Coupon' }
      & Pick<Coupon, 'id' | 'redeemed' | 'amountInCent' | 'createdAt' | 'redeemedAt' | 'code'>
    )>> }
  )> }
);

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'isAdmin' | 'phone'>
    & { cards?: Maybe<Array<Maybe<(
      { __typename?: 'LinkedCard' }
      & Pick<LinkedCard, 'createdAt' | 'paymentMethod' | 'cardInfos' | 'shopperReference' | 'registrationStrategy' | 'verified' | 'provider'>
    )>>>, ecomEmails?: Maybe<Array<(
      { __typename?: 'EcomEmail' }
      & Pick<EcomEmail, 'email' | 'verified' | 'verifiedAt'>
    )>>, coupons?: Maybe<Array<(
      { __typename?: 'Coupon' }
      & Pick<Coupon, 'id' | 'code' | 'amountInCent' | 'redeemed' | 'createdAt' | 'redeemedAt'>
    )>>, events?: Maybe<Array<(
      { __typename?: 'Events' }
      & Pick<Events, 'type' | 'timestamp'>
      & { id: Events['_id'] }
    )>> }
  )> }
);

export type LinkCardMutationVariables = Exact<{
  shopperReference: Scalars['String'];
}>;


export type LinkCardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'linkCardToAccount'>
);

export type DashboardLayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardLayoutQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'isAdmin' | 'points' | 'phone'>
  )> }
);

export type GetLoyaltyInfoQueryVariables = Exact<{
  customerKey: Scalars['String'];
}>;


export type GetLoyaltyInfoQuery = (
  { __typename?: 'Query' }
  & { getLoyaltyInfo?: Maybe<(
    { __typename?: 'POS_LoyaltyCard' }
    & Pick<Pos_LoyaltyCard, 'shopperReference' | 'id'>
    & { User?: Maybe<(
      { __typename?: 'POS_User' }
      & Pick<Pos_User, 'name' | 'email'>
    )> }
  )> }
);

export type LevelQueryVariables = Exact<{ [key: string]: never; }>;


export type LevelQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'points'>
  )>, merchant?: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'levelsConfig' | 'pointsPerCent'>
  )> }
);

export type GetMerchantConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantConfigQuery = (
  { __typename?: 'Query' }
  & { merchant?: Maybe<(
    { __typename?: 'Admin_Merchant' }
    & Pick<Admin_Merchant, 'id' | 'name' | 'pointsPerCent' | 'welcomePoints' | 'merchantName'>
    & { levelsConfig?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_LevelConfig' }
      & Pick<Admin_LevelConfig, 'name' | 'from' | 'to' | 'discountInPercent' | 'mainColor'>
    )>>> }
  )> }
);

export type UpdateMerchantConfigMutationVariables = Exact<{
  levelsConfig: Scalars['String'];
  welcomePoints: Scalars['Int'];
  pointsPerCent: Scalars['Float'];
}>;


export type UpdateMerchantConfigMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateMerchant?: Maybe<(
    { __typename?: 'Admin_Merchant' }
    & Pick<Admin_Merchant, 'name' | 'pointsPerCent' | 'welcomePoints' | 'merchantName'>
    & { levelsConfig?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_LevelConfig' }
      & Pick<Admin_LevelConfig, 'name' | 'from' | 'to' | 'discountInPercent' | 'mainColor'>
    )>>> }
  )> }
);

export type CreatePaymentAdyenMutationVariables = Exact<{
  amount: Scalars['Int'];
}>;


export type CreatePaymentAdyenMutation = (
  { __typename?: 'Mutation' }
  & { pos_startPaymentAdyen?: Maybe<(
    { __typename?: 'POS_TerminalResponseAdyen' }
    & Pick<Pos_TerminalResponseAdyen, 'payment'>
  )> }
);

export type CreatePaymentPayNlMutationVariables = Exact<{
  amount: Scalars['Int'];
}>;


export type CreatePaymentPayNlMutation = (
  { __typename?: 'Mutation' }
  & { pos_startPayment?: Maybe<(
    { __typename?: 'POS_TerminalResponse' }
    & Pick<Pos_TerminalResponse, 'transactionId' | 'hash'>
  )> }
);

export type GetPaymentInfoQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type GetPaymentInfoQuery = (
  { __typename?: 'Query' }
  & { getPaymentInfo?: Maybe<(
    { __typename?: 'POS_PaymentInfo' }
    & Pick<Pos_PaymentInfo, 'id' | 'orderId' | 'customerKey' | 'data'>
  )> }
);

export type PaymentMethodsQueryVariables = Exact<{
  amountInCent: Scalars['Int'];
  shopperReference?: Maybe<Scalars['String']>;
}>;


export type PaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: (
    { __typename?: 'PaymentMethodResponse' }
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentGroups' }
      & Pick<PaymentGroups, 'name' | 'types'>
    )>>>, paymentMethods?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'brands' | 'supportsRecurring' | 'name' | 'type'>
      & { details?: Maybe<Array<Maybe<(
        { __typename?: 'PaymentMethodDetails' }
        & Pick<PaymentMethodDetails, 'key' | 'type'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'PaymentMethodDetailsItems' }
          & Pick<PaymentMethodDetailsItems, 'id' | 'name'>
        )>>> }
      )>>> }
    )>>> }
  ) }
);

export type CreateRegisterPaymentMutationVariables = Exact<{
  input: CreateRegisterPaymentInput;
}>;


export type CreateRegisterPaymentMutation = (
  { __typename?: 'Mutation' }
  & { registerWithPayment: (
    { __typename?: 'CreatePaymentResponse' }
    & Pick<CreatePaymentResponse, 'redirectURL' | 'resultCode' | 'shopperReference'>
  ) }
);

export type ValidateAppleMerchantMutationVariables = Exact<{
  validationURL?: Maybe<Scalars['String']>;
}>;


export type ValidateAppleMerchantMutation = (
  { __typename?: 'Mutation' }
  & { validateAppleMerchant: (
    { __typename?: 'ValidationResponse' }
    & Pick<ValidationResponse, 'epochTimestamp' | 'expiresAt' | 'merchantSessionIdentifier' | 'nonce' | 'merchantIdentifier' | 'domainName' | 'displayName' | 'signature'>
  ) }
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'phone' | 'notificationStrategy' | 'email'>
  )> }
);

export type NotificationStrategyMutationVariables = Exact<{
  strategy?: Maybe<NotificationStrategy>;
  input?: Maybe<UpdateNotificationStrategyInput>;
}>;


export type NotificationStrategyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNotificationStrategy'>
);

export type GetTransactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTransactionsQuery = (
  { __typename?: 'Query' }
  & { transactions?: Maybe<Array<Maybe<(
    { __typename?: 'Admin_Transaction' }
    & Pick<Admin_Transaction, 'id' | 'points' | 'createdAt' | 'note'>
    & { card?: Maybe<(
      { __typename?: 'Admin_Card' }
      & Pick<Admin_Card, 'paymentMethod'>
    )>, ecomEmail?: Maybe<(
      { __typename?: 'Admin_EcomEmail' }
      & Pick<Admin_EcomEmail, 'email'>
    )> }
  )>>> }
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'Admin_User' }
    & Pick<Admin_User, 'email' | 'id' | 'createdAt' | 'name' | 'phone'>
    & { cards?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_Card' }
      & Pick<Admin_Card, 'shopperReference'>
    )>>>, ecomEmails?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_EcomEmail' }
      & Pick<Admin_EcomEmail, 'email'>
    )>>> }
  )>>> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'admin_deleteUser'>
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
    & { cards?: Maybe<Array<Maybe<(
      { __typename?: 'LinkedCard' }
      & Pick<LinkedCard, 'registrationStrategy' | 'paymentMethod' | 'createdAt'>
    )>>> }
  )> }
);

export type ActivityQueryVariables = Exact<{
  types?: Maybe<Array<Scalars['String']>>;
}>;


export type ActivityQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { eventsCollection?: Maybe<(
      { __typename?: 'EventsCollection' }
      & Pick<EventsCollection, 'eventSet'>
      & { items?: Maybe<Array<(
        { __typename?: 'Events' }
        & Pick<Events, 'type' | 'data' | 'timestamp'>
      )>> }
    )>, events?: Maybe<Array<(
      { __typename?: 'Events' }
      & Pick<Events, 'type' | 'timestamp'>
    )>> }
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { register?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUserMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);


export const GetCardsDocument = gql`
    query getCards {
  cards: admin_cards {
    id
    shopperReference
    paymentMethod
    createdAt
    verified
    cardInfos
    registrationStrategy
    provider
    user: User {
      email
    }
    transactions: Transaction {
      points
    }
  }
}
    `;

/**
 * __useGetCardsQuery__
 *
 * To run a query within a React component, call `useGetCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
        return Apollo.useQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, baseOptions);
      }
export function useGetCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
          return Apollo.useLazyQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, baseOptions);
        }
export type GetCardsQueryHookResult = ReturnType<typeof useGetCardsQuery>;
export type GetCardsLazyQueryHookResult = ReturnType<typeof useGetCardsLazyQuery>;
export type GetCardsQueryResult = Apollo.QueryResult<GetCardsQuery, GetCardsQueryVariables>;
export const DeleteCardDocument = gql`
    mutation deleteCard($id: String!) {
  admin_deleteCard(id: $id)
}
    `;
export type DeleteCardMutationFn = Apollo.MutationFunction<DeleteCardMutation, DeleteCardMutationVariables>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardMutation, DeleteCardMutationVariables>) {
        return Apollo.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(DeleteCardDocument, baseOptions);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<DeleteCardMutation>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<DeleteCardMutation, DeleteCardMutationVariables>;
export const CardsDocument = gql`
    query cards($shopperReference: String!) {
  card(shopperReference: $shopperReference) {
    phone
  }
}
    `;

/**
 * __useCardsQuery__
 *
 * To run a query within a React component, call `useCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsQuery({
 *   variables: {
 *      shopperReference: // value for 'shopperReference'
 *   },
 * });
 */
export function useCardsQuery(baseOptions?: Apollo.QueryHookOptions<CardsQuery, CardsQueryVariables>) {
        return Apollo.useQuery<CardsQuery, CardsQueryVariables>(CardsDocument, baseOptions);
      }
export function useCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardsQuery, CardsQueryVariables>) {
          return Apollo.useLazyQuery<CardsQuery, CardsQueryVariables>(CardsDocument, baseOptions);
        }
export type CardsQueryHookResult = ReturnType<typeof useCardsQuery>;
export type CardsLazyQueryHookResult = ReturnType<typeof useCardsLazyQuery>;
export type CardsQueryResult = Apollo.QueryResult<CardsQuery, CardsQueryVariables>;
export const CreateCardDocument = gql`
    mutation createCard($phoneNumber: String!, $hash: String!, $customerKey: String!) {
  createCard: pos_createCard(phoneNumber: $phoneNumber, hash: $hash, customerKey: $customerKey) {
    id
    shopperReference
    User {
      name
    }
  }
}
    `;
export type CreateCardMutationFn = Apollo.MutationFunction<CreateCardMutation, CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      hash: // value for 'hash'
 *      customerKey: // value for 'customerKey'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>) {
        return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, baseOptions);
      }
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>;
export const CheckCardDocument = gql`
    query checkCard($shopperReference: String!) {
  card(shopperReference: $shopperReference) {
    shopperReference
    paymentMethod
    verified
  }
}
    `;

/**
 * __useCheckCardQuery__
 *
 * To run a query within a React component, call `useCheckCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCardQuery({
 *   variables: {
 *      shopperReference: // value for 'shopperReference'
 *   },
 * });
 */
export function useCheckCardQuery(baseOptions?: Apollo.QueryHookOptions<CheckCardQuery, CheckCardQueryVariables>) {
        return Apollo.useQuery<CheckCardQuery, CheckCardQueryVariables>(CheckCardDocument, baseOptions);
      }
export function useCheckCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCardQuery, CheckCardQueryVariables>) {
          return Apollo.useLazyQuery<CheckCardQuery, CheckCardQueryVariables>(CheckCardDocument, baseOptions);
        }
export type CheckCardQueryHookResult = ReturnType<typeof useCheckCardQuery>;
export type CheckCardLazyQueryHookResult = ReturnType<typeof useCheckCardLazyQuery>;
export type CheckCardQueryResult = Apollo.QueryResult<CheckCardQuery, CheckCardQueryVariables>;
export const CouponsDocument = gql`
    query coupons($redeemed: Boolean) {
  me {
    couponsCollection {
      items(redeemed: $redeemed) {
        code
        redeemed
        amountInCent
        redeemedAt
        createdAt
      }
    }
    id
    coupons {
      id
      redeemed
      amountInCent
      createdAt
      redeemedAt
      code
    }
  }
}
    `;

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *      redeemed: // value for 'redeemed'
 *   },
 * });
 */
export function useCouponsQuery(baseOptions?: Apollo.QueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
        return Apollo.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, baseOptions);
      }
export function useCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
          return Apollo.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, baseOptions);
        }
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsQueryResult = Apollo.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const DashboardDocument = gql`
    query dashboard {
  me {
    id
    email
    name
    isAdmin
    phone
    cards {
      createdAt
      paymentMethod
      cardInfos
      shopperReference
      registrationStrategy
      verified
      provider
    }
    ecomEmails {
      email
      verified
      verifiedAt
    }
    coupons {
      id
      code
      amountInCent
      redeemed
      createdAt
      redeemedAt
    }
    events(limit: 4) {
      id: _id
      type
      timestamp
    }
  }
}
    `;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const LinkCardDocument = gql`
    mutation linkCard($shopperReference: String!) {
  linkCardToAccount(shopperReference: $shopperReference)
}
    `;
export type LinkCardMutationFn = Apollo.MutationFunction<LinkCardMutation, LinkCardMutationVariables>;

/**
 * __useLinkCardMutation__
 *
 * To run a mutation, you first call `useLinkCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkCardMutation, { data, loading, error }] = useLinkCardMutation({
 *   variables: {
 *      shopperReference: // value for 'shopperReference'
 *   },
 * });
 */
export function useLinkCardMutation(baseOptions?: Apollo.MutationHookOptions<LinkCardMutation, LinkCardMutationVariables>) {
        return Apollo.useMutation<LinkCardMutation, LinkCardMutationVariables>(LinkCardDocument, baseOptions);
      }
export type LinkCardMutationHookResult = ReturnType<typeof useLinkCardMutation>;
export type LinkCardMutationResult = Apollo.MutationResult<LinkCardMutation>;
export type LinkCardMutationOptions = Apollo.BaseMutationOptions<LinkCardMutation, LinkCardMutationVariables>;
export const DashboardLayoutDocument = gql`
    query dashboardLayout {
  me {
    isAdmin
    points
    phone
  }
}
    `;

/**
 * __useDashboardLayoutQuery__
 *
 * To run a query within a React component, call `useDashboardLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardLayoutQuery(baseOptions?: Apollo.QueryHookOptions<DashboardLayoutQuery, DashboardLayoutQueryVariables>) {
        return Apollo.useQuery<DashboardLayoutQuery, DashboardLayoutQueryVariables>(DashboardLayoutDocument, baseOptions);
      }
export function useDashboardLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardLayoutQuery, DashboardLayoutQueryVariables>) {
          return Apollo.useLazyQuery<DashboardLayoutQuery, DashboardLayoutQueryVariables>(DashboardLayoutDocument, baseOptions);
        }
export type DashboardLayoutQueryHookResult = ReturnType<typeof useDashboardLayoutQuery>;
export type DashboardLayoutLazyQueryHookResult = ReturnType<typeof useDashboardLayoutLazyQuery>;
export type DashboardLayoutQueryResult = Apollo.QueryResult<DashboardLayoutQuery, DashboardLayoutQueryVariables>;
export const GetLoyaltyInfoDocument = gql`
    query getLoyaltyInfo($customerKey: String!) {
  getLoyaltyInfo: pos_getLoyaltyInfo(customerKey: $customerKey) {
    User {
      name
      email
    }
    shopperReference
    id
  }
}
    `;

/**
 * __useGetLoyaltyInfoQuery__
 *
 * To run a query within a React component, call `useGetLoyaltyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoyaltyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoyaltyInfoQuery({
 *   variables: {
 *      customerKey: // value for 'customerKey'
 *   },
 * });
 */
export function useGetLoyaltyInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>) {
        return Apollo.useQuery<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>(GetLoyaltyInfoDocument, baseOptions);
      }
export function useGetLoyaltyInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>) {
          return Apollo.useLazyQuery<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>(GetLoyaltyInfoDocument, baseOptions);
        }
export type GetLoyaltyInfoQueryHookResult = ReturnType<typeof useGetLoyaltyInfoQuery>;
export type GetLoyaltyInfoLazyQueryHookResult = ReturnType<typeof useGetLoyaltyInfoLazyQuery>;
export type GetLoyaltyInfoQueryResult = Apollo.QueryResult<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>;
export const LevelDocument = gql`
    query level {
  me {
    id
    points
  }
  merchant {
    levelsConfig
    pointsPerCent
  }
}
    `;

/**
 * __useLevelQuery__
 *
 * To run a query within a React component, call `useLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useLevelQuery(baseOptions?: Apollo.QueryHookOptions<LevelQuery, LevelQueryVariables>) {
        return Apollo.useQuery<LevelQuery, LevelQueryVariables>(LevelDocument, baseOptions);
      }
export function useLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LevelQuery, LevelQueryVariables>) {
          return Apollo.useLazyQuery<LevelQuery, LevelQueryVariables>(LevelDocument, baseOptions);
        }
export type LevelQueryHookResult = ReturnType<typeof useLevelQuery>;
export type LevelLazyQueryHookResult = ReturnType<typeof useLevelLazyQuery>;
export type LevelQueryResult = Apollo.QueryResult<LevelQuery, LevelQueryVariables>;
export const GetMerchantConfigDocument = gql`
    query getMerchantConfig {
  merchant: admin_merchant {
    id
    name
    pointsPerCent
    welcomePoints
    merchantName
    levelsConfig: levelsConfigData {
      name
      from
      to
      discountInPercent
      mainColor
    }
  }
}
    `;

/**
 * __useGetMerchantConfigQuery__
 *
 * To run a query within a React component, call `useGetMerchantConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMerchantConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>) {
        return Apollo.useQuery<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>(GetMerchantConfigDocument, baseOptions);
      }
export function useGetMerchantConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>) {
          return Apollo.useLazyQuery<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>(GetMerchantConfigDocument, baseOptions);
        }
export type GetMerchantConfigQueryHookResult = ReturnType<typeof useGetMerchantConfigQuery>;
export type GetMerchantConfigLazyQueryHookResult = ReturnType<typeof useGetMerchantConfigLazyQuery>;
export type GetMerchantConfigQueryResult = Apollo.QueryResult<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>;
export const UpdateMerchantConfigDocument = gql`
    mutation updateMerchantConfig($levelsConfig: String!, $welcomePoints: Int!, $pointsPerCent: Float!) {
  admin_updateMerchant(levelsConfig: $levelsConfig, welcomePoints: $welcomePoints, pointsPerCent: $pointsPerCent) {
    name
    pointsPerCent
    welcomePoints
    merchantName
    levelsConfig: levelsConfigData {
      name
      from
      to
      discountInPercent
      mainColor
    }
  }
}
    `;
export type UpdateMerchantConfigMutationFn = Apollo.MutationFunction<UpdateMerchantConfigMutation, UpdateMerchantConfigMutationVariables>;

/**
 * __useUpdateMerchantConfigMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantConfigMutation, { data, loading, error }] = useUpdateMerchantConfigMutation({
 *   variables: {
 *      levelsConfig: // value for 'levelsConfig'
 *      welcomePoints: // value for 'welcomePoints'
 *      pointsPerCent: // value for 'pointsPerCent'
 *   },
 * });
 */
export function useUpdateMerchantConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantConfigMutation, UpdateMerchantConfigMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantConfigMutation, UpdateMerchantConfigMutationVariables>(UpdateMerchantConfigDocument, baseOptions);
      }
export type UpdateMerchantConfigMutationHookResult = ReturnType<typeof useUpdateMerchantConfigMutation>;
export type UpdateMerchantConfigMutationResult = Apollo.MutationResult<UpdateMerchantConfigMutation>;
export type UpdateMerchantConfigMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantConfigMutation, UpdateMerchantConfigMutationVariables>;
export const CreatePaymentAdyenDocument = gql`
    mutation createPaymentAdyen($amount: Int!) {
  pos_startPaymentAdyen(amount: $amount) {
    payment
  }
}
    `;
export type CreatePaymentAdyenMutationFn = Apollo.MutationFunction<CreatePaymentAdyenMutation, CreatePaymentAdyenMutationVariables>;

/**
 * __useCreatePaymentAdyenMutation__
 *
 * To run a mutation, you first call `useCreatePaymentAdyenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentAdyenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentAdyenMutation, { data, loading, error }] = useCreatePaymentAdyenMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useCreatePaymentAdyenMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentAdyenMutation, CreatePaymentAdyenMutationVariables>) {
        return Apollo.useMutation<CreatePaymentAdyenMutation, CreatePaymentAdyenMutationVariables>(CreatePaymentAdyenDocument, baseOptions);
      }
export type CreatePaymentAdyenMutationHookResult = ReturnType<typeof useCreatePaymentAdyenMutation>;
export type CreatePaymentAdyenMutationResult = Apollo.MutationResult<CreatePaymentAdyenMutation>;
export type CreatePaymentAdyenMutationOptions = Apollo.BaseMutationOptions<CreatePaymentAdyenMutation, CreatePaymentAdyenMutationVariables>;
export const CreatePaymentPayNlDocument = gql`
    mutation createPaymentPayNL($amount: Int!) {
  pos_startPayment(amount: $amount) {
    transactionId
    hash
  }
}
    `;
export type CreatePaymentPayNlMutationFn = Apollo.MutationFunction<CreatePaymentPayNlMutation, CreatePaymentPayNlMutationVariables>;

/**
 * __useCreatePaymentPayNlMutation__
 *
 * To run a mutation, you first call `useCreatePaymentPayNlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentPayNlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentPayNlMutation, { data, loading, error }] = useCreatePaymentPayNlMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useCreatePaymentPayNlMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentPayNlMutation, CreatePaymentPayNlMutationVariables>) {
        return Apollo.useMutation<CreatePaymentPayNlMutation, CreatePaymentPayNlMutationVariables>(CreatePaymentPayNlDocument, baseOptions);
      }
export type CreatePaymentPayNlMutationHookResult = ReturnType<typeof useCreatePaymentPayNlMutation>;
export type CreatePaymentPayNlMutationResult = Apollo.MutationResult<CreatePaymentPayNlMutation>;
export type CreatePaymentPayNlMutationOptions = Apollo.BaseMutationOptions<CreatePaymentPayNlMutation, CreatePaymentPayNlMutationVariables>;
export const GetPaymentInfoDocument = gql`
    query getPaymentInfo($orderId: String!) {
  getPaymentInfo: pos_getPaymentInfo(orderId: $orderId) {
    id
    orderId
    customerKey
    data
  }
}
    `;

/**
 * __useGetPaymentInfoQuery__
 *
 * To run a query within a React component, call `useGetPaymentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentInfoQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetPaymentInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentInfoQuery, GetPaymentInfoQueryVariables>) {
        return Apollo.useQuery<GetPaymentInfoQuery, GetPaymentInfoQueryVariables>(GetPaymentInfoDocument, baseOptions);
      }
export function useGetPaymentInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentInfoQuery, GetPaymentInfoQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentInfoQuery, GetPaymentInfoQueryVariables>(GetPaymentInfoDocument, baseOptions);
        }
export type GetPaymentInfoQueryHookResult = ReturnType<typeof useGetPaymentInfoQuery>;
export type GetPaymentInfoLazyQueryHookResult = ReturnType<typeof useGetPaymentInfoLazyQuery>;
export type GetPaymentInfoQueryResult = Apollo.QueryResult<GetPaymentInfoQuery, GetPaymentInfoQueryVariables>;
export const PaymentMethodsDocument = gql`
    query paymentMethods($amountInCent: Int!, $shopperReference: String) {
  paymentMethods(amountInCent: $amountInCent, shopperReference: $shopperReference) {
    groups {
      name
      types
    }
    paymentMethods {
      brands
      supportsRecurring
      details {
        key
        type
        items {
          id
          name
        }
      }
      name
      type
    }
  }
}
    `;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      amountInCent: // value for 'amountInCent'
 *      shopperReference: // value for 'shopperReference'
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
        return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, baseOptions);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
          return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, baseOptions);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export const CreateRegisterPaymentDocument = gql`
    mutation createRegisterPayment($input: CreateRegisterPaymentInput!) {
  registerWithPayment(input: $input) {
    redirectURL
    resultCode
    shopperReference
  }
}
    `;
export type CreateRegisterPaymentMutationFn = Apollo.MutationFunction<CreateRegisterPaymentMutation, CreateRegisterPaymentMutationVariables>;

/**
 * __useCreateRegisterPaymentMutation__
 *
 * To run a mutation, you first call `useCreateRegisterPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegisterPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegisterPaymentMutation, { data, loading, error }] = useCreateRegisterPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegisterPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegisterPaymentMutation, CreateRegisterPaymentMutationVariables>) {
        return Apollo.useMutation<CreateRegisterPaymentMutation, CreateRegisterPaymentMutationVariables>(CreateRegisterPaymentDocument, baseOptions);
      }
export type CreateRegisterPaymentMutationHookResult = ReturnType<typeof useCreateRegisterPaymentMutation>;
export type CreateRegisterPaymentMutationResult = Apollo.MutationResult<CreateRegisterPaymentMutation>;
export type CreateRegisterPaymentMutationOptions = Apollo.BaseMutationOptions<CreateRegisterPaymentMutation, CreateRegisterPaymentMutationVariables>;
export const ValidateAppleMerchantDocument = gql`
    mutation validateAppleMerchant($validationURL: String) {
  validateAppleMerchant(validationURL: $validationURL) {
    epochTimestamp
    expiresAt
    merchantSessionIdentifier
    nonce
    merchantIdentifier
    domainName
    displayName
    signature
  }
}
    `;
export type ValidateAppleMerchantMutationFn = Apollo.MutationFunction<ValidateAppleMerchantMutation, ValidateAppleMerchantMutationVariables>;

/**
 * __useValidateAppleMerchantMutation__
 *
 * To run a mutation, you first call `useValidateAppleMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAppleMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAppleMerchantMutation, { data, loading, error }] = useValidateAppleMerchantMutation({
 *   variables: {
 *      validationURL: // value for 'validationURL'
 *   },
 * });
 */
export function useValidateAppleMerchantMutation(baseOptions?: Apollo.MutationHookOptions<ValidateAppleMerchantMutation, ValidateAppleMerchantMutationVariables>) {
        return Apollo.useMutation<ValidateAppleMerchantMutation, ValidateAppleMerchantMutationVariables>(ValidateAppleMerchantDocument, baseOptions);
      }
export type ValidateAppleMerchantMutationHookResult = ReturnType<typeof useValidateAppleMerchantMutation>;
export type ValidateAppleMerchantMutationResult = Apollo.MutationResult<ValidateAppleMerchantMutation>;
export type ValidateAppleMerchantMutationOptions = Apollo.BaseMutationOptions<ValidateAppleMerchantMutation, ValidateAppleMerchantMutationVariables>;
export const SettingsDocument = gql`
    query settings {
  me {
    id
    phone
    notificationStrategy
    email
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, baseOptions);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, baseOptions);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const NotificationStrategyDocument = gql`
    mutation notificationStrategy($strategy: NotificationStrategy, $input: UpdateNotificationStrategyInput) {
  updateNotificationStrategy(strategy: $strategy, input: $input)
}
    `;
export type NotificationStrategyMutationFn = Apollo.MutationFunction<NotificationStrategyMutation, NotificationStrategyMutationVariables>;

/**
 * __useNotificationStrategyMutation__
 *
 * To run a mutation, you first call `useNotificationStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationStrategyMutation, { data, loading, error }] = useNotificationStrategyMutation({
 *   variables: {
 *      strategy: // value for 'strategy'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationStrategyMutation(baseOptions?: Apollo.MutationHookOptions<NotificationStrategyMutation, NotificationStrategyMutationVariables>) {
        return Apollo.useMutation<NotificationStrategyMutation, NotificationStrategyMutationVariables>(NotificationStrategyDocument, baseOptions);
      }
export type NotificationStrategyMutationHookResult = ReturnType<typeof useNotificationStrategyMutation>;
export type NotificationStrategyMutationResult = Apollo.MutationResult<NotificationStrategyMutation>;
export type NotificationStrategyMutationOptions = Apollo.BaseMutationOptions<NotificationStrategyMutation, NotificationStrategyMutationVariables>;
export const GetTransactionsDocument = gql`
    query getTransactions {
  transactions: admin_transactions {
    id
    points
    createdAt
    note
    card: Card {
      paymentMethod
    }
    ecomEmail: EcomEmail {
      email
    }
  }
}
    `;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
        return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, baseOptions);
      }
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, baseOptions);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const GetUserDocument = gql`
    query getUser {
  users: admin_users {
    email
    id
    createdAt
    name
    phone
    cards: Card {
      shopperReference
    }
    ecomEmails: EcomEmail {
      email
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  admin_deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UserDocument = gql`
    query user {
  me {
    name
    cards {
      registrationStrategy
      paymentMethod
      createdAt
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const ActivityDocument = gql`
    query activity($types: [String!]) {
  me {
    eventsCollection {
      eventSet
      items(types: $types) {
        type
        data
        timestamp
      }
    }
    id
    events {
      type
      timestamp
    }
  }
}
    `;

/**
 * __useActivityQuery__
 *
 * To run a query within a React component, call `useActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useActivityQuery(baseOptions?: Apollo.QueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
        return Apollo.useQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, baseOptions);
      }
export function useActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
          return Apollo.useLazyQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, baseOptions);
        }
export type ActivityQueryHookResult = ReturnType<typeof useActivityQuery>;
export type ActivityLazyQueryHookResult = ReturnType<typeof useActivityLazyQuery>;
export type ActivityQueryResult = Apollo.QueryResult<ActivityQuery, ActivityQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($email: String!, $password: String!, $name: String!, $phone: String) {
  register(email: $email, password: $password, name: $name, phone: $phone) {
    id
    name
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, baseOptions);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const LoginUserDocument = gql`
    mutation loginUser($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    id
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, baseOptions);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    
// Generated on 17-09-2020 @ 17:49:18
