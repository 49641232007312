import { Button, Form, Segment } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import {
  useCreateCardMutation,
  useGetLoyaltyInfoLazyQuery,
  useGetPaymentInfoQuery,
} from "../graphql/__generated__/graphql_types";

import { useParams } from "react-router-dom";

const FakePOSPayNLPayment = () => {
  const [customerKey, setCustomerKey] = useState<string>();

  const [formState, setFormState] = useState({ phoneNumber: "" });
  const { orderId } = useParams<{ orderId?: string }>();

  const [createCard] = useCreateCardMutation();

  const [
    getLoyaltyInfo,
    { data: loyaltyInfo, loading: loyaltyLoading },
  ] = useGetLoyaltyInfoLazyQuery({ fetchPolicy: "no-cache" });

  const {
    data: paymentData,
    loading: cardLoading,
    refetch,
    startPolling,
    stopPolling,
  } = useGetPaymentInfoQuery({
    variables: { orderId },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    startPolling(500);

    if (paymentData && paymentData.getPaymentInfo) {
      stopPolling();
      setCustomerKey(paymentData.getPaymentInfo.customerKey);

      getLoyaltyInfo({
        variables: {
          customerKey: paymentData.getPaymentInfo.customerKey,
        },
      });
    }
  }, [paymentData, orderId]);

  const handleChange = (event: any) => {
    event.persist();
    const name = event.target.name;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const queryParams = new URLSearchParams(window.location.search);

    await createCard({
      variables: {
        phoneNumber: formState.phoneNumber,
        hash: queryParams.get("hash"),
        customerKey: paymentData.getPaymentInfo.customerKey,
      },
    });

    getLoyaltyInfo({
      variables: {
        customerKey: paymentData.getPaymentInfo.customerKey,
      },
    });

    refetch();
  };

  if (cardLoading || loyaltyLoading)
    return <h2 style={{ textAlign: "center" }}>Loading...</h2>;

  if (!paymentData && !customerKey)
    return (
      <div className="ui centered stackable padded relaxed grid">
        <h4>Payment in process</h4>
      </div>
    );

  return (
    <div className="ui centered stackable padded relaxed grid">
      <div className="centered row">Payment transactionId is {orderId}</div>

      {customerKey && (
        <div className="centered row">Loyalty Key is {customerKey}</div>
      )}

      <div className="centered row">
        <Segment style={{ marginBottom: 24, maxWidth: 400 }}>
          {loyaltyInfo?.getLoyaltyInfo?.User ? (
            <>
              <h4>
                Hi {loyaltyInfo?.getLoyaltyInfo?.User.name}, congrats with
                saving points
              </h4>
            </>
          ) : loyaltyInfo &&
            loyaltyInfo.getLoyaltyInfo &&
            !loyaltyInfo.getLoyaltyInfo.User ? (
            <>
              It seems you haven't registered yourself for our loyalty
              programme. So please look at your WhatsApp or SMS inbox
            </>
          ) : customerKey && !loyaltyInfo?.getLoyaltyInfo ? (
            <>
              <h4 className="ui header"> Enter mobile number</h4>
              <form>
                <Form.Input
                  name="phoneNumber"
                  placeholder="+31619006234"
                  value={formState.phoneNumber}
                  onChange={handleChange}
                  style={{
                    marginBottom: 14,
                  }}
                ></Form.Input>
                <Button
                  secondary
                  content="Submit"
                  type="submit"
                  onClick={handleSubmit}
                ></Button>
              </form>
            </>
          ) : (
            "Payment not complete"
          )}
        </Segment>
      </div>
    </div>
  );
};

export default FakePOSPayNLPayment;
