import { Link, Redirect, Route, Switch } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import ErrorComponent from "./components/ErrorComponent/ErrorComponent";
import Layout from "./components/Layout/Layout";
import LinkedCards from "./components/Tables/LinkedCards";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import MerchantConfig from "./components/Tables/MerchantConfig";
import NotFoundPage from "./pages/NotFoundPage";
import React from "react";
import Transactions from "./components/Tables/Transactions";
import UserTable from "./components/Tables/UserTable";

const userQuery = gql`
  query AdminUser {
    me {
      id
      isAdmin
    }
  }
`;

const LoginWarning = () => {
  return (
    <div style={{ textAlign: "center", marginTop: 100 }}>
      You need to be logged in to see this page. Please login at{" "}
      <Link to="/login">here</Link>
    </div>
  );
};

export const AdminRouter = () => {
  const { data, loading, error } = useQuery(userQuery);

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) return <ErrorComponent error={error} />;

  if (data && !data.me.isAdmin) {
    return <LoginWarning />;
  }

  return (
    <Layout>
      <Switch>
        <Route exact path="/admin">
          <Redirect to="/admin/users"></Redirect>
        </Route>
        <Route exact path="/admin/users">
          <UserTable />
        </Route>
        <Route exact path="/admin/transactions">
          <Transactions />
        </Route>
        <Route exact path="/admin/linked-cards">
          <LinkedCards />
        </Route>
        <Route exact path="/admin/merchant">
          <MerchantConfig />
        </Route>

        <Route path="/admin*">
          <NotFoundPage />
        </Route>
      </Switch>
    </Layout>
  );
};
