const en = {
  "dashboard.showMore.events": "Show all activity",
  "dashboard.recent.events": "Recent Activity",
  "dashboard.coupons.title": "Coupons",
  "dashboard.coupons.redeemed": "redeemed",
  "dashboard.coupons.active": "active",
  "dashboard.coupons.showMore": "Show all Coupons",
  "dashboard.save.webshop": "Save in Our Webshops",
  "dashboard.save.description":
    "With this account you automatically collect points when you buy something in our webshop",
  "dashboard.saveStore.title": "Save in our Stores",
  "dashboard.saveStore.description":
    "With this payment method you automatically save in our stores",
  "dashboard.saveStore.verified": "verified",
  "dashboard.saveStore.connectNew": "Connect another card",
  "dashboard.saveStore.notVerified": "not verified",
  "dashboard.button.funnierMessage":
    "Life is funnier with notifications, activate them for updates about your account",
  "dashboard.button.activateNotifications": "Activate Notifications",
  "dashboard.geen.coupons": "No coupons so far",

  "dashboard.events.subtitle": "At {date, date}",
  "dashboard.coupons.amount":
    "{amount,  number, ::sign-accounting compact-short currency/EUR}",
  // Dashboard: dates and times
  // TODO: merge date and time to one string
  "dashboard.coupons.date.redeemed": "{date, date, short}",
  "dashboard.coupons.time.redeemed": "{time, time, short}",
  "dashboard.coupons.date.created": "{date, date, short}",
  "dashboard.coupons.time.created": "{time, time, short}",
  "dashboard.card.date.created": "{date, date, short}",
  "dashboard.card.time.created": "{time, time, short}",

  //
  "levelsOverview.missingPoints": `more {missingPoints, number}pts
  ({missingAmount, number,::sign-accounting compact-short currency/EUR})
  to level {level, number}`,
  //

  "levelsOverview.coupon.title": "{couponValue, number, ::percent} coupon",
  "levelsOverview.coupon.description": `Receive a coupon with a value of {couponValue} on every purchase
   of your purchase amount.The coupons are valid for 3 months.`,
  "levelsOverview.yourPoints": "your Points",
  "levelsOverview.yourLevel": "Your Level",
  //
  "activity.title": "Recent Activity",
  "activity.points.added":
    "{points, number} {points, plural, one {point} other {points}} added",
  "activity.welcomePoints": "{points, number} welcome points",
  "activity.card.verified": "card verified",
  "activity.coupon.created":
    "{coupon, number, ::sign-accounting compact-short currency/EUR }  coupon created",
  "activity.date": "{actDate, date, short}",
  "activity.time": "{actTime, time, short}",
  //
  "coupons.title": "Coupons",
  "coupons.button.redeemed": "Redeemed",
  "coupons.button.active": "Active",
  "coupons.number.redeemed":
    "{coupon, number, ::sign-accounting compact-short currency/EUR}",
  "coupons.number.active":
    "{coupon, number, ::sign-accounting compact-short currency/EUR}",
  "coupons.active.subtitle": "Active Coupons",
  "coupons.redeemed.subtitle": "Redeemed Coupons",
  "coupons.list.item":
    " Code: {couponCode} of {couponValue, number, ::sign-accounting compact-short currency/EUR } ",
  "coupons.status.redeemed": "redeemed",
  "coupons.status.active": "active",
  "coupons.redeemed.at": "{date, date, short}",
  "coupons.created.at": "{date, date, short}",

  "coupons.reedemed.time": "{time, time, short}",
  "coupons.created.time": "{time, time, short}",
  //
  "navbar.dashboard": "Dashboard",
  "navbar.coupons": "Coupons",
  "navbar.activity": "Activity",
  "navbar.settings": "Settings",
  //
  "settings.title": "Settings",
  "settings.text":
    "Choose how you would like to be notified about account updates:",
  "settings.email": "Emails will be sent to ",
  "settings.sms": "Send to: ",
  "settings.save": "Save",
  "settings.change": "Change Settings",
  //
  "login.register": "Register",
  "login.or": "or",
  "login.password": "Password",
  //
  "register.create.account": "Create Account",
  "register.link.payment": "Link Payment",
  "register.verification": "Verification",
  "register.name": "Name",
  "register.phone": "Phone",
  "register.password": "Password",
  "register.confirm": "Confirm Password",
  "register.acceptTerms":
    "I accept the Terms and Conditions and the Privacy Statement",
  "register.button.create": "Create Account",
  //
  "card.decision.signUp.text": "sign up to our loyalty program",
  "card.decision.signUp.button": "sign up",
  "card.decision.logIn.text": "log in to your account",
  "card.decision.logIn.button": "login",
};

const nl: { [key in keyof typeof en]: string } = {
  "dashboard.showMore.events": "Meer Over Activiteit",
  "dashboard.recent.events": "Recente Activiteit",
  "dashboard.coupons.title": "Coupons",
  "dashboard.coupons.redeemed": "gebruikt",
  "dashboard.coupons.active": "geldig",
  "dashboard.coupons.showMore": "Meer Over Coupons",
  "dashboard.save.webshop": "Sparen in de Webshop",
  "dashboard.save.description":
    "Met dit account verzamel je automatisch punten wanneer je iets koopt in onze webshop",
  "dashboard.saveStore.title": "Sparen in de winkel",
  "dashboard.saveStore.description":
    "Met deze betaalmethode spaar je automatisch in onze winkels",
  "dashboard.saveStore.verified": "geverifieerd",
  "dashboard.saveStore.connectNew": "Voeg nog 1 toe",
  "dashboard.saveStore.notVerified": "niet geverifieerd",
  "dashboard.button.funnierMessage":
    "Het leven is leuker met meldingen, activeer ze voor updates over uw account",
  "dashboard.button.activateNotifications": "Meldingen Activeren",
  "dashboard.events.subtitle": "At {date, date} {date, time, short}",
  "dashboard.coupons.amount":
    "{amount,  number, ::sign-accounting compact-short currency/EUR}",
  // Dashboard: dates and times
  "dashboard.coupons.date.redeemed": "{date, date, short}",
  "dashboard.coupons.time.redeemed": "{time, time, short}",
  "dashboard.coupons.date.created": "{date, date, short}",
  "dashboard.coupons.time.created": "{time, time, short}",
  "dashboard.card.date.created": "{date, date, short}",
  "dashboard.card.time.created": "{time, time, short}",

  "dashboard.geen.coupons": "Geen Coupons",
  //

  "levelsOverview.missingPoints": `nog {missingPoints, number}pts
  ({missingAmount, number,::sign-accounting compact-short currency/EUR})
  voor niveau {level, number}`,
  "levelsOverview.coupon.title": "{couponValue, number, ::percent} coupon",
  "levelsOverview.coupon.description": `Ontvang op iedere aankoop een coupon met een waarde van s
  {couponValue} van jouw aankoopbedrag.
  De coupon zijn 3 maanden geldig.
  `,
  "levelsOverview.yourPoints": "Jouw Punten",
  "levelsOverview.yourLevel": "Huidig Niveau",
  //
  "activity.title": "Recente Activiteit",
  "activity.points.added": "{points, number} points added",
  "activity.welcomePoints": "{points, number} welkom punten",
  "activity.card.verified": " kaart geverifieerd",
  "activity.coupon.created": "{coupon, number} € coupon gemaakt",
  "activity.date": "{actDate , date, medium}",
  "activity.time": "{actTime, time, short}",
  //
  "coupons.title": "Coupons",
  "coupons.button.redeemed": "Gebruikt",
  "coupons.button.active": "Geldig",
  "coupons.number.redeemed":
    "{coupon, number, ::sign-accounting compact-short currency/EUR}",
  "coupons.number.active":
    "{coupon, number, ::sign-accounting compact-short currency/EUR}",
  "coupons.active.subtitle": "Open kortingen",
  "coupons.redeemed.subtitle": "Ingewisselde Kortingens",
  "coupons.list.item":
    "Code: {couponCode} van {couponValue, number, ::sign-accounting compact-short currency/EUR }",
  "coupons.status.redeemed": "gebruikt",
  "coupons.status.active": "geldig",

  "coupons.redeemed.at": "{date, date, short}",
  "coupons.created.at": "{date, date, short}",

  "coupons.reedemed.time": "{time, time, short}",
  "coupons.created.time": "{time, time, short}",
  //
  "navbar.dashboard": "Overzicht",
  "navbar.coupons": "Coupons",
  "navbar.activity": "Ativiteit",
  "navbar.settings": "Installigen",
  //

  "settings.title": "Settings",
  "settings.text": "Hoe wil je meldingen over je account ontvangen:",
  "settings.email": "E-mails worden verzonden naar: ",
  "settings.sms": "SMS worden verzonden naar:  ",
  "settings.save": "Opslaan",
  "settings.change": "Change Settings",
  //
  "login.register": "Registreer",
  "login.or": "of",
  "login.password": "Watchwoord",
  //
  "register.create.account": "Account aanmaken",
  "register.link.payment": "Koppelen betalling",
  "register.verification": "Verificatie",
  "register.name": "Naam",
  "register.phone": "Telefoon",
  "register.password": "Wachtword",
  "register.confirm": "Herhaal Wachtword",
  "register.acceptTerms":
    "Ik accepteer de terms and conditions en de privacy statement",
  "register.button.create": "Account Aanmaken",
  //

  "card.decision.signUp.text": "inschrijven voor spaarprogramma",
  "card.decision.signUp.button": "inschrijven",
  "card.decision.logIn.text": "inloggen in bestaand account",
  "card.decision.logIn.button": "inloggen",
};

export const translations = {
  en,
  nl,
};
