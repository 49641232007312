import React, { Props } from "react";
import { useIntl } from "react-intl";

export function RegistrationLayout({ children }: Props<{}>) {
  const { formatMessage: t } = useIntl();
  // function stepCompleted(step: string) {
  //   if (
  //     step === "registration" &&
  //     ["connect", "verification"].includes(props.activeStep)
  //   ) {
  //     return true;
  //   }
  //   if (step === "connect" && ["verification"].includes(props.activeStep)) {
  //     return true;
  //   }
  //
  //   return false;
  // }

  return (
    <div>
      <div className="ui centered stackable padded relaxed grid">
        <div className="centered row">
          <div className="column" style={{ textAlign: "center" }}>
            <img
              className="main-logo"
              style={{ width: 250 }}
              src="https://shop.loyyo.nl/wp-content/uploads/2020/07/cropped-Nikkie-Plessen-Logo-Black-Website-301x105-1.png"
              alt="Nikkie loyalty header registration"
            />
          </div>
        </div>
        <div className="row">
          <img
            className="subheader"
            src={require("../../assets/header-image.png")}
            style={{ width: "100%", objectFit: "contain" }}
            alt="Nikkie loyalty steps header"
          />
        </div>

        <div v-if="showSteps" className="centered row">
          <div className="eight wide column">
            <div className="ui three ordered steps">
              <div className="step">
                <div className="content">
                  <div className="title">
                    {t({ id: "register.create.account" })}
                  </div>
                </div>
              </div>
              <div className="step">
                <div className="content">
                  <div className="title">
                    {t({ id: "register.link.payment" })}
                  </div>
                </div>
              </div>
              <div className="step">
                <div className="content">
                  <div className="title">
                    {t({ id: "register.verification" })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ui centered stackable padded relaxed grid">
        {children}
      </div>
    </div>
  );
}
