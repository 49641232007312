import {
  Container,
  Grid,
  Menu,
  MenuItemProps,
  Segment,
} from "semantic-ui-react";
import React, { useState } from "react";

import { Link } from "react-router-dom";

const SideMenu = (props: any) => {
  const [activeMenu, setActiveMenu] = useState<string>("");

  const handleItemClick = (e: any, { name }: MenuItemProps) => {
    if (name) setActiveMenu(name);
  };

  return (
    <>
      <Segment style={{ minHeight: "100vh" }}>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column width={2}>
              <Menu
                fluid
                vertical
                inverted
                style={{
                  minHeight: "100vh",
                  marginLeft: -15,
                  marginTop: -15,
                  borderRadius: 0,
                  marginBottom: -15,
                }}
              >
                <Menu.Item header as="h3">
                  Admin Dashboard
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/admin/users"
                  name="users"
                  active={activeMenu === "users"}
                  onClick={handleItemClick}
                />
                <Menu.Item
                  as={Link}
                  to="/admin/transactions"
                  name="transactions"
                  active={activeMenu === "transactions"}
                  onClick={handleItemClick}
                />
                <Menu.Item
                  as={Link}
                  to="/admin/linked-cards"
                  name="cards"
                  active={activeMenu === "cards"}
                  onClick={handleItemClick}
                />
                <Menu.Item
                  as={Link}
                  to="/admin/merchant"
                  name="merchant"
                  active={activeMenu === "merchant"}
                  onClick={handleItemClick}
                />
                <Menu.Item
                  style={{
                    backgroundColor: "lightseagreen",
                    marginTop: "10vh",
                  }}
                  as={Link}
                  name="To user-dashboard"
                  to={"/dashboard"}
                  target="_blank"
                ></Menu.Item>
              </Menu>
            </Grid.Column>
            <Grid.Column width={14}>
              <Container fluid>{props.children}</Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

export default SideMenu;
