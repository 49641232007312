import { Button, Table } from "semantic-ui-react";
import {
  useDeleteUserMutation,
  useGetUserQuery,
} from "../../../../../graphql/__generated__/graphql_types";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import React from "react";

const UserTable = () => {
  const { data, loading, refetch } = useGetUserQuery();
  const [deleteUser] = useDeleteUserMutation();

  let users: any[] = [];

  const handleDeleteUser = async (id: string) => {
    await deleteUser({ variables: { id } });
    refetch();
  };

  if (loading) return <LoadingSpinner />;

  if (data && data.users) users = data.users;

  return (
    <>
      <h1>User dashboard</h1>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>User Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Cards</Table.HeaderCell>
            <Table.HeaderCell>Emails</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users.map(
            (
              { name, email, cards, ecomEmails, createdAt, id, phone },
              index
            ) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>{id}</Table.Cell>
                  <Table.Cell>{name} </Table.Cell>
                  <Table.Cell>{email} </Table.Cell>
                  <Table.Cell>{cards?.length} </Table.Cell>
                  <Table.Cell>{ecomEmails?.length} </Table.Cell>
                  <Table.Cell>{phone} </Table.Cell>
                  <Table.Cell>
                    {new Date(createdAt).toLocaleString()}{" "}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Button icon="trash" onClick={() => handleDeleteUser(id)}>
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            }
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default UserTable;
